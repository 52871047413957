.resume {
    background: #fff;
    overflow: hidden;
}


.resume h1 {
    font: 1.8rem/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
}
.resume h1 span {
    border-bottom: 3px solid #11ABB0;
    padding-bottom: 6px;
}
.resume h3 {
    font: 2.5rem/30px 'opensans-bold', sans-serif;
    color: #000;
}

.experience {
    border-bottom: 1px solid #E8E8E8;
    padding-top: 10%;
}

.resume .education {
    padding-bottom: 10%;
}

.resume .work {
    padding-bottom: 10%;
}

.resume .info {
    font: 1.6rem/24px 'librebaskerville-italic', serif;
    color: #6E7881;
    margin-bottom: 1.8rem;
    margin-top: 0.9rem;
}
.resume .info span {
    margin-right: 5px;
    margin-left: 5px;
}
.resume .date {
    font: 1.5rem/24px 'opensans-regular', sans-serif;
    margin-top: 6px;
}

.resume .skill {
    padding-top: 10%;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}
.bars .skills {
  	margin-top: 36px;
   list-style: none;
}
.bars li {
   position: relative;
  	margin-bottom: 60px;
  	background: #ccc;
  	height: 42px;
  	border-radius: 3px;
}
.bars li em {
	font: 15px 'opensans-bold', sans-serif;
   color: #313131;
	text-transform: uppercase;
   letter-spacing: 2px;
	font-weight: normal;
   position: relative;
	top: -36px;
}
.bar-expand {
   position: absolute;
   left: 0;
   top: 0;

   margin: 0;
   padding-right: 24px;
  	background: #313131;
   display: inline-block;
  	height: 42px;
   line-height: 42px;
   border-radius: 3px 0 0 3px;
}

@media (max-width: 575.98px) {
    .experience {
        padding-top: 25%;
    }
    
    .resume .education {
        padding-bottom: 25%;
    }
    
    .resume .work {
        padding-bottom: 25%;
    }
}

@media (max-width: 768px) {
    .experience {
        text-align: center;
    }

    .resume h1 {
        margin-bottom: 10%;
    }
}