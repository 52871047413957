.portfolio {
    background: #ebeeee;
    padding-top: 8%;
    padding-bottom: 8%;
    overflow: hidden;
}

.portfolio h1 {
    font: 2rem/24px 'opensans-semibold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    color: #95A3A3;
    padding-bottom: 5%;
}

.portfolio img {
    height: 150px;
}

/* Portfolio Content */
.portfolio-wrapper .columns { padding-bottom: 15%; }

.portfolio-item .item-wrap {
   overflow: hidden;
   position: relative;

   -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
   display: block;
   cursor: pointer;
}


/* overlay */
.portfolio-item .item-wrap .overlay {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;

	opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;

   background: url(../../images/overlay-bg.png) repeat;
}
.portfolio-item .item-wrap .link-icon {
   display: block;
   color: #fff;
   height: 0.3rem;
   width: 0.3rem;
   font-size: 1.8rem;
   line-height: 3rem;
   text-align: center;

   opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;

   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -1.5rem;
   margin-top: -1.5rem;
}
.portfolio-item .item-wrap img {
   vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta { padding: 1.8rem }
.portfolio-item .portfolio-item-meta h5 {
   font: 1.4rem/21px 'opensans-bold', sans-serif;
   color: #fff;
}
.portfolio-item .portfolio-item-meta p {
   font: 1.2rem/18px 'opensans-light', sans-serif;
   color: #c6c7c7;
   margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
	opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
   opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}


@media (max-width: 575.98px) { 
    .portfolio {
        padding-top: 25% !important;
        padding-bottom: 25% !important;
    } 
}

@media (max-width: 767.98px) {
    .portfolio {
        padding-top: 15%;
        padding-bottom: 15%;
    } 

    .portfolio-item {
        padding: 10% 5%;
    }

    .portfolio h1 {
        margin-bottom: 0;
    }
}

@media (max-width: 991.98px) {
    .portfolio img {
        height: auto;
    }
}