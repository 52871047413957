.about {
    padding-top: 9.6rem;
    padding-bottom: 6.6rem;
    overflow: hidden;
    background: #2B2B2B;
}

.about h2 {
    font: 22px/30px 'opensans-bold', sans-serif;
    color: #fff;
    margin-bottom: 12px;
}

.about p {
    line-height: 30px;
    color: #7A7A7A;
}

.about .profile-pic {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100%;
}

.about .download {
    padding-top: 2rem;
}

.about .download .button {
    padding: 2rem 4rem;
    background: #444;
}
.about .download .button:hover {
    background: #fff;
    color: #2B2B2B;
}
.about .download .button i {
    margin-right: 15px;
    font-size: 20px;
}

.about a, #about a:visited  { color: #fff; }
.about a:hover, #about a:focus { color: #11ABB0; }

@media (max-width: 991.98px) {
    .about .download .button {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

@media (max-width: 767.98px) {
    .about .download .button {
        padding-left: 5rem;
        padding-right: 5rem;
    }

}