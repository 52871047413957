footer {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    position: relative;
    background-color: #000;
}

footer .copyright li {
    list-style: none;
}

footer .social {
    padding-top: 6%;
    padding-bottom: 2%;
    font-size: 2rem;
    list-style: none;
}

footer .social-links li {
    list-style: none;
}

footer .fa {
    color: #fff;
}

footer .fa:hover {
    color: #c4baba;
}

.go-top {
	position: absolute;
	top: -2.4rem;
    left: 50%;
    margin-left: -3rem;
}

.go-top a {
	text-decoration: none;
	border: 0 none;
	display: block;
	width: 60px;
	height: 60px;
	background-color: #000;

	-webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    color: #fff;
    font-size: 2.1rem;
    line-height: 6rem;
 	border-radius: 100%;
}

@media (max-width: 575.98px) {
    footer .social {
        padding-top: 20%;
        font-size: 1.5rem;
    }
}
