header {
    position: relative;
    height: 100vh;
    min-height: 500px;
    width: 100%;
    background: #161415 url(../../images//header-background@2x.png) no-repeat top center;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    -o-background-size: cover !important;
    text-align: center;
    overflow: hidden;
    margin: 0;
}


header .banner {
    padding-top: 15%;
}

header .banner-text h1 {
    font: 9rem/1.1em 'opensans-bold', sans-serif;
    color: #fff;
    letter-spacing: -2px;
    margin: 0 auto 18px auto;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}
header .banner-text h3 {
    font: 2rem/1.9em 'librebaskerville-regular', serif;
    font-weight: bold;
    color: #c4baba;
    margin: 0 auto;
    width: 70%;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
header .banner-text h3 span,
header .banner-text h3 a {
    color: #fff;
}
header .banner-text hr {
    width: 60%;
    margin: 1.8rem auto 2.4rem auto;
    border-color: #2F2D2E;
    border-color: rgba(150, 150, 150, .1);
}

li {
    list-style: none;
}

header .fa {
    color: #fff;
}

header .fa:hover {
    color: #c4baba;
}

header .scrolldown {
    padding-top: 8%;
}




@media (max-width: 575.98px) {
    header .banner-text h1 {
        font-size: 7rem;
    }
    header .banner-text h3 {
        font-size: 1.73rem;
    }
    header .banner-text hr {
        margin: auto;
    }
    header .social {
        padding-top: 10%;
    }
}

@media (max-width: 767.98px) {
    header {
        background: #161415 url(../../images//header-background.png) no-repeat top center;
    }
}

