.navbar {
    min-height: 3rem;
    width: auto;
    text-align: center;
}

.navbar .nav-item {
    padding: 8px 13px;
    font: 1.3rem 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    text-align: center;
    line-height: 32px;
   
}

.navbar .nav-item a {
    color: #fff !important;
}

.navbar .nav-item .active {
    color: #F06000 !important;
}

.notHome {
    background-color: #333;
}



@media (max-width: 991.98px) {
    .navbar-collapse {
        background-color: #333;
    }
}

